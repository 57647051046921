import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Img from './contact-image'
import styled from 'styled-components'

/* Component */
const ContactPage = ({ location }) => {
  const seoTitle = 'Contact House of Iman for Qawwali shows & Sufi events'
  const social = {
    email: 'BookHouseofIman@gmail.com',
    phoneNumber: '(516) 244-2916',
    instagram: '@House_of_Iman_Qawwali',
  }
  const summary =
    'If you are looking for a cultural experience, please contact us! We perform shows & private events all across the the world. Please reach out at any of the following channels. '

  return (
    <Layout location={location}>
      <SEO title={seoTitle} />
      <ContactHeader>Contact Us</ContactHeader>
      <TextBox>
        <SummaryText>{summary}</SummaryText>
        <InfoText>
          <b>Email: </b>
          <SocialLink href={`mailto:${social.email}`}>
            {social.email}
          </SocialLink>
        </InfoText>
        <InfoText>
          <b>Phone: </b>
          {social.phoneNumber}
        </InfoText>
        <InfoText>
          <b>Instagram: </b>
          <SocialLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/house_of_iman_qawwali/"
          >
            {social.instagram}
          </SocialLink>
        </InfoText>
      </TextBox>
      <ImageContainer>
        <Image />
      </ImageContainer>
    </Layout>
  )
}

export default ContactPage

/* Styling */
const SocialLink = styled.a`
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
const TextBox = styled.div`
  /* margin: 0 10px; */
  font-size: ${(props) => props.theme.pageText};
`
const InfoText = styled.div`
  margin: 1.5% 0;
  font-variant: small-caps;
`
const SummaryText = styled.div``
const ContactHeader = styled.div`
  font-size: ${(props) => props.theme.headerText};
  text-align: center;
  margin: 0% 10% 3%;
  border-bottom: 2px solid;
  border-bottom-color: ${(props) => props.theme.gray};
  line-height: 1.3;
  color: ${(props) => props.theme.gray};
  font-variant: small-caps;
`
const Image = styled(Img)`
  max-height: inherit;
  transition: all 0.5s ease;
`
const ImageContainer = styled.div`
  max-height: 700px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
`
